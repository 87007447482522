'use client';

import { Suspense } from 'react';
import { useIsVercelian } from '@pyra/vercel-api/hooks/use-is-vercelian';
import { VercelToolbar } from '@vercel/toolbar/next';

const isDevelopment = process.env.NODE_ENV === 'development';

export function ClientScripts(): JSX.Element {
  const isVercelian = useIsVercelian();

  return (
    <Suspense>
      {isDevelopment || isVercelian ? <VercelToolbar /> : null}
    </Suspense>
  );
}
